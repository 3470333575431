<template>
  <div class="zt-page-content">
    <div v-if="item && item.id" class="zt-block">
      <div class="list">
        <div class="flex">
          <div class="left">交易类型</div>
          <div v-if="item && item.bizType=='bq_order'">购买设计作品版权</div>
          <div v-if="item && item.bizType=='pur_order'">采购商品</div>
          <div v-if="item && item.bizType=='dy_order'">打样</div>
        </div>
        <div class="flex">
          <div class="left">交易渠道</div>
          <div>
            <div v-if="item && item.type=='offline'">线下支付</div>
            <div v-if="item && item.type=='wx'">微信支付</div>
          </div>
        </div>
        <div class="flex">
          <div class="left">交易流水号</div>
          <div>{{order_code}}</div>
        </div>
        <div class="flex">
          <div class="left">交易时间</div>
          <div v-if="item && item.payedAt">{{item.payedAt | moment("YYYY-MM-DD HH:mm:ss")}}</div>
        </div>
        <div class="flex border">
          <div class="left">
              <span v-if="item.confirmCompId == comp.id">收款金额</span>
              <span v-else>付款金额</span>
            </div>
          <div v-if="item && item.amount" class="price">￥{{item.amount | priceFilt}}</div>
        </div>
        <div v-if="item && item.type=='offline'" class="flex">
          <div class="left">
              <span v-if="item.confirmCompId == comp.id">对方付款账户</span>
              <span v-else>我方付款账户</span>
          </div>
          <div v-if="item && item.sendInfo">{{item.sendInfo.account}}</div>
        </div>
        <div v-if="item && item.type=='offline'" class="flex">
          <div class="left">
              <span v-if="item.confirmCompId == comp.id">对方付款银行名称</span>
              <span v-else>我方付款银行名称</span>
          </div>
          <div v-if="item && item.sendInfo">{{item.sendInfo.bank.split(',')[0]}}</div>
        </div>
        <div v-if="item && item.type=='offline'" class="flex border">
          <div class="left">
              <span v-if="item.confirmCompId == comp.id">对方付款银行开户行</span>
              <span v-else>我方付款银行开户行</span>
          </div>
          <div v-if="item && item.sendInfo">{{item.sendInfo.bank.split(',')[1]}}</div>
        </div>
        <div v-if="item && item.type=='offline'" class="flex">
          <div class="left">
              <span v-if="item.confirmCompId == comp.id">我方收款账户</span>
              <span v-else>对方收款账户</span>
          </div>
          <div v-if="item && item.receivInfo">{{item.receivInfo.account}}</div>
        </div>
        <div v-if="item && item.type=='offline'" class="flex">
          <div class="left">
              <span v-if="item.confirmCompId == comp.id">我方收款银行名称</span>
              <span v-else>对方收款银行名称</span>
          </div>
          <div v-if="item && item.receivInfo">{{item.receivInfo.bank}}</div>
        </div>
        <div class="list-img flex" v-if="item && item.type=='offline'">
          <div style="width:137px;padding-bottom:10px;" class="left">付款凭证或截图</div>
          <div v-for="(item,idx) in item.proofImg" :key="idx" class="img">
            <img :src="imgUrl+item+'!width_250px'" alt />
            <!-- <div class="bg_img" :style="{backgroundImage: 'url(' + imgUrl+item+'!width_250px' + ')'}"></div> -->
          </div>
        </div>
        <div style="fon-size:12px;color:#CCC;">确认收款必须拥有财务“确认收款权限”的人才可操作，其他人无法操作</div>
      </div>
    </div>
    <div class="zt-block">
      <div style="padding:30px 44px;">
        <el-button @click="back()" style="margin-right:50px;" size="small" type="primary">返 回</el-button>
        <el-button
          v-if="item && item.confirmCompId == comp.id && item.state == 'WAITCONFIRM'"
          @click="qrPay()"
          size="small"
          type="primary"
        >确认收款</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { IMG_URL_PRE } from "@/config";
import { payConfirmId, QrofflinePayBq } from "@/service/pay";
import { payId,qrOfflinePay } from "@/service/pay";
import get from "lodash/get";
export default {
  components: {},
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      order_id: this.$route.query.order_id,
      order_code: this.$route.query.order_code,
      item: null,
      comp:null,
    };
  },
  created() {
    this.comp = this.$store.state.loginUser.comp;
    console.log("pay");
    this.orderInfo();
  },
  mounted() {},
  computed: {
    privids() {
      return get(this.$store.state, "loginUser.user.privids", []);
    }
  },
  methods: {
    orderInfo() {
      payId(this.order_code).then(rst => {
        this.item = rst;
      });
    },
    back() {
      this.$router.go(-1);
    },
    qrPay() {console.log(this.privids);
      if (this.privids.indexOf(1008) >= 0) {
        this.$confirm("确认是否到账?", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.submit();
        });
      } else {
        this.$message.error("请财务确认是否到账，财务权限可操作!");
      }
    },
    submit() {
      if (this.item && this.item.bizType == "bq_order") {
        QrofflinePayBq({ trans_no: this.order_id })
          .then(rst => {
            this.$message.success("确认付款成功");
            this.back();
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      } else if (this.item && this.item.bizType == "pur_order") {
        payConfirmId("pur_order", this.order_id)
          .then(rst => {
            this.$message.success("确认付款成功");
            this.back();
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      } else if (this.item && this.item.bizType == "dy_order") {
        qrOfflinePay(this.item.id).then(rst => {
            this.$message.success("确认付款成功");
            this.back();
        }).catch(err => {
            this.$message.error(err.message);
        });
      }
    }
  }
};
</script>
<style scoped src="./style.css"></style>
<style scoped>
.list {
  padding: 16px 20px;
}
.list > div {
  font-size: 14px;
  color: #808080;
  line-height: 40px;
}
.border {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
}
.list > div div.left {
  width: 145px;
  text-align: right;
  font-size: 16px;
  color: #4d4d4d;
  margin-right: 32px;
}
.price {
  font-size: 20px;
  color: #ee1818;
}
.list > div.list-img {
  padding-bottom: 10px;
}
.list-img .img {
  width: 250px;
  text-align: center;
  height: 150px;
  border: 1px dashed rgb(204, 204, 204);
  overflow: hidden;
  display: inline-block;
  margin-top: 15px;
}
.list-img .img img {
  height: 100%;
}
</style>